import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import RoomTypeStore from 'Stores/RoomTypesStore/One';

import TopBar from 'Shared/components/TopBar';
import TopBarContent from '../Edit/components/TopBarContent';
import PageContent from '../Edit/components/PageContent';
import { LayoutPageContent } from '../Edit/index.styled';

import { UserProvider } from 'Context/FormLanguageContext';
import FormState, { fields } from 'Pages/RoomTypes/components/FormState';
import Actions from '../Edit/components/Actions';

@withRouter
@observer
class Edit extends Component {
  @observable form = new FormState(fields);

  state = {
    language: 'ru'
  };

  @computed get room_type() {
    const { roomTypeStore } = this.props;
    if (!roomTypeStore.isFetched) return undefined;
    return roomTypeStore.room_type;
  }

  @computed get get_state() {
    const { history } = this.props;

    const { state } = history.location;
    return state;
  }

  componentDidUpdate() {
    if (this.room_type) {
      this.form.update(this.room_type.toForm());
    }
  }

  componentDidMount() {
    const { roomTypeStore } = this.props;
    this.get_state && roomTypeStore.fetch(this.get_state?.id);

    this.scrollToTop();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  onClickHandler = (language) => {
    if (this.state.language === language) return;
    this.setState({ language: language });
  };

  render() {
    const { roomTypeStore } = this.props;
    return (
      <UserProvider
        value={{
          currentLanguage: this.state.language,
          handleLanguageChange: this.onClickHandler
        }}
      >
        <div className="layout__page">
          <div className="layout__page-form-controls">
            <TopBar>
              <TopBarContent />
            </TopBar>
          </div>

          <LayoutPageContent>
            <div className="page__body">
              <PageContent roomTypeStore={roomTypeStore} form={this.form} />
            </div>
          </LayoutPageContent>
          <Actions
            form={this.form}
            room_type={this.room_type}
            roomTypeStore={roomTypeStore}
            hasCopy={!!this.get_state?.id}
            downloadedImages={this.get_state?.downloadedImages}
          />
        </div>
      </UserProvider>
    );
  }
}

Edit.defaultProps = {
  roomTypeStore: RoomTypeStore.create()
};

Edit.propTypes = {
  roomTypeStore: PropTypes.object,
  history: PropTypes.object.isRequired
};

export default Edit;
