import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loaders';
import styled from 'styled-components';

import RoomTypes from '../RoomTypes';

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
`;

@withRouter
@observer
class PageBody extends Component {
  state = {
    loading: false
  };

  handleSetState = (newState) => {
    this.setState(newState);
  };

  render() {
    const { room_types } = this.props;
    const { loading } = this.state;
    return loading
      ? (
      <LoaderContainer>
        <Loader className="loading" type="line-scale" active color="#999" />
      </LoaderContainer>
        )
      : (
      <div className="page__body">
        <div className="groups">
          <div className="groups__item">
            <RoomTypes room_types={room_types} setState={this.handleSetState} />
          </div>
        </div>
      </div>
        );
  }
}

PageBody.propTypes = {
  room_types: PropTypes.object.isRequired
};

export default PageBody;
