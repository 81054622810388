import './styles/main.scss';

import React from 'react';
import { StateInspector } from 'reinspect';
import { createRoot } from 'react-dom/client';
import { Provider } from 'mobx-react';

import { store, chatStore, hotelStore, intl } from './base_store';
import App from './pages/App';

import { IntlProvider } from 'react-intl';

const root = createRoot(document.getElementById('root'));
root.render(
  <StateInspector>
    <Provider store={store} chatStore={chatStore} hotelStore={hotelStore}>
      <IntlProvider locale={intl.locale} messages={intl.messages}>
        <App />
      </IntlProvider>
    </Provider>
  </StateInspector>
);
