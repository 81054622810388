import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import DuplicateIcon from 'react-icons/lib/md/control-point-duplicate';

import {
  RoomTypeView,
  RoomTypeContentLeft,
  RoomTypeContentRigth,
  RoomTypeTitle,
  RoomTypeCount,
  RoomTypeContent,
  RoomTypeTitleWrapper,
  RoomTypeDescriptionLeft,
  RoomTypeDescriptionRigth,
  RoomTypeDescription,
  DescriptionItem,
  DescriptionItemText,
  RoomTypeButtons,
  RoomTypeImage
} from './RoomType.styled';

import { ReactComponent as Area } from 'Icons/area.svg';
import { ReactComponent as Bed } from 'Icons/room_bed.svg';
import { ReactComponent as ExtraBed } from 'Icons/extra_bed.svg';

import { formatMessage } from 'Utils/formatMessage';
import NoPhoto from 'Shared/ui/NoPhotoContainer';
import store from 'store';
import CapacityModal from 'Pages/RoomTypes/containers/CapacityModal';
import Button from 'components/ui/Button/Button';

@withRouter
@observer
class RoomType extends Component {
  @computed get getPathname() {
    const { id } = this.props.room_type;
    return `/room_types/${id}`;
  }

  @computed get isActive() {
    const { pathname } = this.props.location;
    return this.getPathname === pathname;
  }

  state = {
    isOpenModal: false,
    capacity: 0,
    isCapacityUpdate: false
  };

  componentDidMount() {
    const { room_type } = this.props;
    this.setState({ capacity: room_type.capacity.total });
  }

  componentDidUpdate() {
    const {
      room_type: {
        capacity: { total }
      }
    } = this.props;
    const { isCapacityUpdate, capacity } = this.state;

    if (total !== capacity && !isCapacityUpdate) this.handleSetCapacity(total);
  }

  handleSetCapacity = (capacityValue) => {
    this.setState({ capacity: capacityValue, isCapacityUpdate: true });
  };

  closeModal = () => {
    this.setState({ isOpenModal: false });
  };

  openModal = () => {
    this.setState({ isOpenModal: true });
  };

  redirectToEdit = () => {
    const { history, room_type } = this.props;
    history.push(`/room_types/${room_type.id}/edit`);
  };

  downloadImg = async (images) => {
    const arr = [];
    for (const img of images) {
      const resp = await fetch(img.url);
      const blob = await resp.blob();
      arr.push(blob);
    }
    return arr;
  };

  handleDuplicate = async (e) => {
    const { history, room_type, setState } = this.props;
    const values = room_type.toJSON();
    e.preventDefault();

    setState({ loading: true });
    const downloadedImages = await this.downloadImg(values.images);
    setState({ loading: false });
    history.push('/room_types/new', { id: room_type.id, downloadedImages });
  };

  render() {
    const { room_type } = this.props;

    const generalLanguage = store.get('language');
    const ucFirst = (str) => {
      if (!str) return str;
      return str[0].toUpperCase() + str.slice(1);
    };

    return (
      <RoomTypeView>
        <RoomTypeContent>
          <RoomTypeContentLeft>
            <RoomTypeTitleWrapper>
              <RoomTypeTitle>
                {ucFirst(room_type.name_translations[generalLanguage])}
              </RoomTypeTitle>
              <RoomTypeCount>
                {formatMessage('room_types.capacity', {
                  capacity: this.state.capacity
                })}
              </RoomTypeCount>
            </RoomTypeTitleWrapper>

            <RoomTypeDescription>
              <RoomTypeDescriptionLeft>
                <DescriptionItem>
                  <Area />
                  <DescriptionItemText>
                    {formatMessage('room_types.room_count', {
                      rooms: room_type.rm_count
                    })}{' '}
                    {room_type.area}{' '}
                    {formatMessage('room_types.area_placeholder')}
                  </DescriptionItemText>
                </DescriptionItem>

                <DescriptionItem>
                  <ExtraBed />
                  <DescriptionItemText>
                    {formatMessage('room_types.extra_beds_count', {
                      beds: room_type.extra_beds
                    })}
                  </DescriptionItemText>
                </DescriptionItem>
              </RoomTypeDescriptionLeft>

              <RoomTypeDescriptionRigth>
                <DescriptionItem>
                  <Bed />
                  <DescriptionItemText>
                    {formatMessage('room_types.beds_count', {
                      beds: room_type.beds
                    })}
                  </DescriptionItemText>
                </DescriptionItem>
              </RoomTypeDescriptionRigth>
            </RoomTypeDescription>

            <RoomTypeButtons>
              <Button variant="secondary" onClick={this.openModal}>
                {' '}
                {formatMessage('room_types.change_the_quantity')}
              </Button>

              <Button variant="secondary" onClick={this.redirectToEdit}>
                {' '}
                {formatMessage('shared.edit')}
              </Button>

              <Button variant="secondary" onClick={this.handleDuplicate}>
                <i className="icon">
                  <DuplicateIcon />
                </i>
              </Button>
            </RoomTypeButtons>
          </RoomTypeContentLeft>

          <RoomTypeContentRigth>
            {room_type.images.length > 0
              ? (
              <RoomTypeImage src={room_type.images[0].url} />
                )
              : (
              <NoPhoto />
                )}
          </RoomTypeContentRigth>
        </RoomTypeContent>

        {this.state.isOpenModal && (
          <CapacityModal
            isOpen={this.state.isOpenModal}
            closeModal={this.closeModal}
            id={room_type.id}
            setCapacity={this.handleSetCapacity}
          />
        )}
      </RoomTypeView>
    );
  }
}

RoomType.propTypes = {
  room_type: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  roomTypeStore: PropTypes.object.isRequired,
  setState: PropTypes.func
};

export default RoomType;
