import axios from 'axios';
import Qs from 'qs';
import store from 'store';

const instanceBlob = axios.create({
  responseType: 'blob',
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'brackets' })
});

instanceBlob.interceptors.request.use(config => {
  const userStore = store.get('userStore');

  if (userStore && userStore.token) {
    config.headers.Authorization = `Bearer ${userStore.token}`;
  }

  return config;
});

instanceBlob.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      store.remove('userStore');
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

export default instanceBlob;
