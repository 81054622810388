import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'Utils/moment';
import dayjs from 'dayjs';

import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import { FormattedMessage } from 'react-intl';

import { PeriodStyled } from 'Styles/styled';

@inject('hotelStore')
@observer
class Periods extends Component {
  @computed get isDisabled() {
    const { form } = this.props;
    return form.$('rates').size === 0;
  }

  handleAdd = e => {
    e.preventDefault();
    const { form } = this.props;
    form.addPeriods();
  };

  handleDelete = periodId => {
    const { form } = this.props;
    form.removePeriodById(periodId);
  };

  handleEdit = periodId => {
    const { form } = this.props;
    form.editPeriod(periodId);
  };

  handleCancelEdit = () => {
    const { form } = this.props;
    form.cancelEdit();
  };

  handleSave = () => {
    const { form } = this.props;
    form.savePeriod();
  };

  handleDateChange = dates => {
    const { form } = this.props;
    form.dateChange(dates);
  };

  handleCopyPeriod = periodId => {
    const { form } = this.props;
    form.copyPeriod(periodId);
  }

  handleRateChange = (value, rate) => {
    const { form } = this.props;
    form.ratePriceChange(rate, value);
  };

  get filteredPeriods() {
    const {
      form: { periods, editedPeriodId, isAdding },
      opened
    } = this.props;
    const filtered = opened
      ? periods
      : periods.filter(
        (period) =>
          editedPeriodId === period.id ||
            dayjs(period.end_date).diff(dayjs()) > 0
      );

    // Применяем сортировку только если не в процессе добавления нового периода
    return isAdding
      ? filtered
      : filtered.sort((left, right) =>
        moment(left.start_date).diff(right.start_date)
      );
  }

  render() {
    const {
      form,
      form: { editedPeriodId, isAdding, dataRangeError, isPeriodCopied }
    } = this.props;

    const hasPeriods = this.filteredPeriods.length > 0;
    const listItems = this.filteredPeriods
      .map(period => {
        return (
          <PeriodStyled
            key={period.id}
            form={form}
            period={period}
            isEditable={period.id && editedPeriodId === period.id}
            onEdit={() => this.handleEdit(period.id)}
            onDelete={() => this.handleDelete(period.id)}
            copyPeriod={() => this.handleCopyPeriod(period.id)}
            onCancelEdit={this.handleCancelEdit}
            onDateChange={this.handleDateChange}
            onRateChange={this.handleRateChange}
            onSave={this.handleSave}
            dataRangeError={dataRangeError}
            isPeriodCopied={isPeriodCopied}
          />
        );
      });

    return (
      <div className='form periods'>
        {hasPeriods
          ? (
            <div className='periods__items'>{listItems}</div>
            )
          : (
            <div className='periods_empty'>
              <p>
                <FormattedMessage id='periods.empty' />
              </p>
            </div>
            )}

        {!isAdding && (
          <div className='form__fields'>
            <div className='form__field action'>
              <button
                className='button green'
                disabled={this.isDisabled}
                onClick={this.handleAdd}
              >
                <FormattedMessage id='periods.add' />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Periods.propTypes = {
  form: PropTypes.object.isRequired,
  periods: PropTypes.object.isRequired,
  opened: PropTypes.bool.isRequired
};

export default Periods;
