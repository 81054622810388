import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed, observable } from 'mobx';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { serialize } from 'object-to-formdata';
import cleanDeep from 'clean-deep';

import ConfirmModal from 'Components/ui/ConfirmModal';

import Button from 'Components/ui/Button';

import {
  Footer,
  FooterLeft,
  FooterRight,
  ButtonsWrapper
} from './Actions.styled';

@withRouter
@inject('store')
@observer
class Actions extends Component {
  @observable openedConfirmModal = false;

  @computed get isValid() {
    const { form } = this.props;

    if (form.changed && !form.validating) {
      return form.isValid;
    }

    if (form.touched && !form.validating) {
      return form.isValid;
    }

    return form.focused || form.isValid;
  }

  @computed get isLoading() {
    const { room_type, roomTypeStore } = this.props;
    if (roomTypeStore) {
      return roomTypeStore && roomTypeStore.isPending;
    }
    return room_type && room_type.isPending;
  }

  setOpenedConfirmModal = (flag) => {
    this.openedConfirmModal = flag;
  };

  updateHandler = (e) => {
    e.preventDefault();

    this.props.form.onSubmit(e, {
      onSuccess: this.successSubmitHandler
    });
  };

  cancelHandler = () => {
    this.navigateTo('/room_types');
  };

  destroyHandler = (e) => {
    e.preventDefault();
    this.setOpenedConfirmModal(false);

    this.props.room_type.destroy().then((_) => this.navigateTo('/room_types'));
  };

  successSubmitHandler = (form) => {
    const { room_type, roomTypeStore, hasCopy, downloadedImages } = this.props;
    let data = cleanDeep({ ...form.values() });
    if (hasCopy) {
      // Copy
      data.images = (data.images || []).filter(
        (image) => image._destroy === true
      );
      data = { room_type: data };
      data = serialize(data);

      if (data.images) {
        data.images.forEach((image, index) => {
          if (image.image) {
            data.append(`room_type[images][${index}][image]`, image.image);
          }
        });
      }

      const blobImages = downloadedImages || [];
      if (blobImages) {
        blobImages.forEach((file) => {
          data.append('room_type[images][][image]', file);
        });
      }

      const files =
        (form.$('uploads').files && form.$('uploads').files[0]) || [];
      if (files) {
        files.forEach((file) => {
          data.append('room_type[images][][image]', file);
        });
      }

      roomTypeStore
        .create(data)
        .then((_) => this.navigateTo('/room_types'))
        .catch((error) => this.errorSubmitHandler(error));
    } else {
      // Update
      data.images = (data.images || []).filter(
        (image) => image._destroy === true
      );
      data = { room_type: data };
      data = serialize(data);

      if (data.images) {
        data.images.forEach((image, index) => {
          if (image.image) {
            data.append(`room_type[images][${index}][image]`, image.image);
          }
        });
      }

      const files =
        (form.$('uploads').files && form.$('uploads').files[0]) || [];
      if (files) {
        files.forEach((file) => {
          data.append('room_type[images][][image]', file);
        });
      }

      if (roomTypeStore) {
        roomTypeStore
          .create(data)
          .then((_) => this.navigateTo('/room_types'))
          .catch((error) => this.errorSubmitHandler(error));
      } else {
        room_type
          .update(data)
          .then((_) => this.navigateTo('/room_types'))
          .catch((error) => this.errorSubmitHandler(error));
      }
    }
  };

  errorSubmitHandler(error) {
    const { store } = this.props;
    const { data } = error.response;

    store.notifyStore.create({
      header: 'Произошла ошибка!',
      type: 'error',
      messages: data.messages
    });
  }

  navigateTo = (path) => {
    const { history } = this.props;
    history.push(path);
  };

  render() {
    const { roomTypeStore, room_type } = this.props;
    return (
      <Footer>
        {!this.isValid && (
          <p className="text error">
            <FormattedMessage id="shared.check_for_errors" />
          </p>
        )}

        <ButtonsWrapper>
          <FooterLeft>
            <Button
              onClick={this.updateHandler}
              size="large"
              loading={this.isLoading}
            >
              <FormattedMessage id="shared.confirm" />
            </Button>

            <Button
              onClick={this.cancelHandler}
              variant="secondary"
              size="large"
            >
              <FormattedMessage id="shared.cancel" />
            </Button>
          </FooterLeft>
          {!roomTypeStore && (
            <FooterRight>
              <Button
                onClick={() => this.setOpenedConfirmModal(true)}
                variant="danger"
                size="large"
                loading={this.isLoading}
              >
                <FormattedMessage id="shared.remove_room" />
              </Button>
            </FooterRight>
          )}
        </ButtonsWrapper>

        {this.openedConfirmModal && (
          <ConfirmModal
            title={<FormattedMessage id="shared.remove_room_ask" />}
            description={
              <FormattedMessage
                id="shared.remove_room_name_ask"
                values={{ name: room_type.name }}
              />
            }
            onApply={this.destroyHandler}
            onCancel={() => this.setOpenedConfirmModal(false)}
          />
        )}
      </Footer>
    );
  }
}

Actions.propTypes = {
  form: PropTypes.object.isRequired,
  room_type: PropTypes.object.isRequired,
  roomTypeStore: PropTypes.object,
  history: PropTypes.object,
  store: PropTypes.object,
  hasCopy: PropTypes.bool,
  downloadedImages: PropTypes.array
};

export default Actions;
