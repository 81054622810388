import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import RoomType from './RoomType';

@withRouter
@observer
class RoomTypes extends Component {
  render() {
    const listItems = this.props.room_types.map((item) => {
      return (
        <RoomType
          key={item.id}
          room_type={item}
          setState={this.props.setState}
        />
      );
    });

    return <div>{listItems}</div>;
  }
}

RoomTypes.propTypes = {
  room_types: PropTypes.object.isRequired,
  setState: PropTypes.func
};

export default RoomTypes;
