import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { NSSogaz } from '@types';

import Chart from 'react-google-charts';
import Tooltip from './Tooltip';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

type Props = React.PropsWithChildren<{
  className?: string | undefined,
  compareStatus: NSSogaz.TMapping['compare_status']
}>

function Diff({ compareStatus, className }: Props) {
  const { rates, services, prices } = compareStatus;

  const data = [
    [
      { type: 'string', id: 'President' },
      { type: 'string', id: 'dummy bar label' },
      { type: 'string', role: 'tooltip' },
      { type: 'date', id: 'Start' },
      { type: 'date', id: 'End' }
    ],
    ...rates.map(rate => [
      'Нет в Sanatory.ru',
      '',
      ReactDOMServer.renderToString(
        <Tooltip diff={rate} />
      ),
      dayjs(rate.start_date).toDate(),
      dayjs(rate.end_date).toDate()
    ]),
    ...services.map(service => [
      'Нет в АО "СОГАЗ"',
      '',
      ReactDOMServer.renderToString(
        <Tooltip diff={service} />
      ),
      dayjs(service.start_date).toDate(),
      dayjs(service.end_date).toDate()
    ]),
    ...prices.map(price => [
      'Цены не совпадают',
      '',
      ReactDOMServer.renderToString(
        <Tooltip diff={price} />
      ),
      dayjs(price.start_date).toDate(),
      dayjs(price.end_date).toDate()
    ])
  ];

  return (
    <Wrapper className={className}>
      <Chart
        chartType="Timeline"
        height={'120px'}
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
          fontSize: 12,
          allowHtml: true,
          timeline: {
            rowLabelStyle: {
              fontName: 'inherit',
              fontSize: 12,
              color: '#474d66;'
            },
            barLabelStyle: {
              fontName: 'inherit',
              fontSize: 8
            }
          }
        }}
        rootProps={{ 'data-testid': '1' }}
      />
    </Wrapper>
  );
}

export default Diff;
