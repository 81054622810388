import store from 'store';

const success = (response) => response;

const error = (error) => {
  if (error?.response?.status === 401) {
    store.remove('userStore');
    window.location = '/login';
  } else {
    return Promise.reject(error);
  }
};

export default [success, error];
