import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import { withRouter } from 'react-router-dom';
import { formatMessage } from 'Utils/formatMessage';
import { Checkbox } from 'evergreen-ui';
import Cookies from 'js-cookie';

@withRouter
@inject('priceListsStore')
@observer
class PriceList extends Component {
  @computed get status() {
    const { status } = this.props.price_list;

    if (status === 'created') {
      return {
        label: 'status booked',
        text: formatMessage('tariffs.statuses.created')
      };
    } else if (status === 'uploaded') {
      return {
        label: 'status draft',
        text: formatMessage('tariffs.statuses.uploaded')
      };
    } else if (status === 'confirmed') {
      return {
        label: 'status confirmed',
        text: formatMessage('tariffs.statuses.confirmed')
      };
    } else {
      return {
        label: 'status',
        text: formatMessage('tariffs.statuses.unknown')
      };
    }
  }

  handleCheckboxChange = (e) => {
    e.stopPropagation();
    const { priceListsStore, price_list } = this.props;
    priceListsStore.toggleSelection(price_list.id);
  };

  handleItemClick = (event) => {
    if (!event.target.closest('.item.checkbox')) {
      const { history, price_list } = this.props;
      history.push(`/price_lists/${price_list.id}`);
    }
  };

  render() {
    const { price_list, priceListsStore } = this.props;
    const isSelected = priceListsStore.selected_ids.includes(price_list.id);
    const hasCookie = Cookies.get('restriction');
    return (
      <div className="price-lists__item">
        <div className="caption" onClick={this.handleItemClick}>
          {hasCookie && (
            <div className="item checkbox" onClick={(e) => e.stopPropagation()}>
              <Checkbox
                checked={isSelected}
                onChange={this.handleCheckboxChange}
              />
            </div>
          )}

          <div className="item name">{price_list.number}</div>

          <div className="item source">
            <span className={this.status.label}>{this.status.text}</span>
          </div>

          <div className="item period">
            {price_list.startDate} - {price_list.endDate}
          </div>
        </div>
      </div>
    );
  }
}

PriceList.propTypes = {
  price_list: PropTypes.object.isRequired,
  priceListsStore: PropTypes.object,
  history: PropTypes.object

};

export default PriceList;
