import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable, computed, reaction } from 'mobx';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Rate from './Rate';
import Form from './RateForm';
import Control, { ControlStore } from './Control';
import FormState, { fields } from './RateForm/FormState';
import getSymbolFromCurrency from 'Utils/currencySymbolMap';

const ListHeaderRate = styled.div`
  display: flex;
  border-bottom: 1px solid silver;
  padding-bottom: 13px;
  color: silver;

  > :last-child {
    width: 15%;
  }
`;

const ListHeaderLeft = styled.div`
  width: 61%;
`;

@inject('hotelStore')
@observer
class Rates extends Component {
  constructor(props) {
    super(props);
    this.rateForm = new FormState(this.defaultValues());
  }

  @observable isAdding = false;
  @observable tariffType = '';

  componentDidMount() {
    this.roomTypeHandler = reaction(
      () => {
        const { form } = this.props;
        return form.has('room_type') && form.$('room_type.id').value;
      },
      hasRoomType => {
        const { control } = this.props;
        hasRoomType ? control.enable() : control.disable();
      },
      { fireImmediately: true }
    );
    this.tariffType = this.props.form.values().type;
    this.closeAllForms();
  }

  componentDidUpdate() {
    if (this.tariffType !== this.props.form.values().type) {
      this.closeAllForms();
      this.rateForm.reset();
      this.isAdding = false;
      this.tariffType = this.props.form.values().type;
    }
  }

  componentWillUnmount() {
    this.roomTypeHandler();
  }

  defaultValues() {
    fields.values.price.currency = this.props.hotelStore.hotel.currency;
    return fields;
  }

  @computed get hasRates() {
    const { rates } = this.props;

    return (
      rates.fields
        .values()
        .filter(rate => !(rate.has('_destroy') && rate.$('_destroy').value))
        .length > 0
    );
  }

  editHandler = rate => {
    const { form } = this.props;
    const values = rate.values();
    this.rateForm.update(values);
    form.openRateForm(values);
    this.isAdding = false;
  };

  closeAllForms() {
    const { form, rates } = this.props;
    form.closeAllRatesForms(rates.values());
  }

  addHandler = value => {
    this.isAdding = value;
    this.closeAllForms();
    if (value) {
      this.rateForm.update({
        type: 'adult_on_main_bed',
        description: 'Взрослый на основном месте с подселением',
        price: {
          amount: 0,
          currency: this.props.hotelStore.hotel.currency
        },
        age_group: 'adult',
        age_period: {
          from: 0,
          to: 0
        },
        occupation: 'main',
        count: 0,
        id: null
      });
    }
  };

  render() {
    const { form, form: { ratesIsOpenForm }, rates, control, hotelStore: { hotel } } = this.props;

    const currentСurrency = hotel.toJSON().currency;
    const currentCurrencySymbol = getSymbolFromCurrency(currentСurrency);
    
    const listItems = rates.fields
      .values()
      .sort(form.sortRates)
      .map(rate => {
        const isOpenForm = ratesIsOpenForm.length &&
          ratesIsOpenForm.filter(rateData => rateData.id === rate.values().id)[0].isOpenForm;

        return (
          <Rate
            key={rate.id}
            rate={rate}
            form={form}
            editHandler={this.editHandler}
            isOpenForm={isOpenForm || false}
            control={control}
            rateForm={this.rateForm}
            addHandler={this.addHandler}
            isAdding={this.isAdding}
          />
        );
      });

    return (
      <div className='rates'>
        <ListHeaderRate>
          <ListHeaderLeft>Тип размещения</ListHeaderLeft>
          <div>Цена, {currentCurrencySymbol}</div>
        </ListHeaderRate>

        {this.hasRates
          ? (
            <div className='rates__list'>{listItems}</div>
            )
          : (
            <div className='rates__empty'>
              <p>
                <FormattedMessage id='rates.empty' />
              </p>
            </div>
            )}

        {this.isAdding && (
          <Form
            form={form}
            control={control}
            rateForm={this.rateForm}
            addHandler={this.addHandler}
            isAdding={this.isAdding}
          />
        )}

        <Control control={control} addHandler={this.addHandler} />
      </div>
    );
  }
}

Rates.defaultProps = {
  control: new ControlStore()
};

Rates.propTypes = {
  rates: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  hotelStore: PropTypes.object.isRequired
};

export default Rates;
