import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import _uniqueId from 'lodash/uniqueId';
import dayjs from 'dayjs';

import Dates from './Dates';
import Rates from './Rates';

@observer
class Seasons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollWidth: null,
      scrollContentHeight: null,
      opened: false
    };
  }

  get filteredSeasons() {
    const { seasons } = this.props;
    const { opened } = this.state;

    return opened
      ? seasons
      : seasons.map(season => ({
        ...season,
        dates: season.dates.filter(date => dayjs(date.end_date).diff(dayjs()) > 0)
      })).filter(season => season.dates.length > 0);
  }

  toggleSeasons = () => {
    const { opened } = this.state;
    this.setState({ opened: !opened });
  }

  render() {
    const { labels } = this.props;
    const { opened } = this.state;

    const headerItems = this.filteredSeasons.map(season => {
      return <Dates key={_uniqueId('date-')} dates={season.dates} />;
    });

    const priceItems = this.filteredSeasons.map(season => {
      return <Rates key={_uniqueId('rate-')} rates={season.rates} />;
    });

    return (
      <div className='seasons'>
        <div className="seasons_wrapper">
        <div className='seasons__caption'>
          {labels.seasons}

        </div>
          <div className="showAll" onClick={this.toggleSeasons}>
            {!opened ? 'Показать все периоды' : 'Показать актуальные периоды'}
          </div>
        </div>

          <div style={{ overflowX: 'auto' }}>
            <div className='seasons__header'>
              {headerItems}
            </div>
            <div className='seasons__prices'>
              {priceItems}
            </div>
          </div>
        </div>
    );
  }
}

Seasons.propTypes = {
  seasons: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired
};

export default Seasons;
