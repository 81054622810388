import { ACTIONS, IAction, IAdapter, STATUSES } from '../types';
import { NSHotel } from '@types';

export const initialState: IAdapter = {
  status: STATUSES.IDLE,
  price_list: {
    status: STATUSES.IDLE,
    data: null
  },
  price_lists: {
    status: STATUSES.IDLE,
    data: []
  },
  mappings: {
    status: STATUSES.IDLE,
    data: []
  },
  tariffs: {
    status: STATUSES.IDLE,
    data: []
  },
  compare: {}
};

const reducer = (state: IAdapter, action: IAction) => {

  switch (action.type) {
    case ACTIONS.PRICE_LIST_FETCH_PENDING: {
      const newState = {
        ...state,
        price_list: {
          ...state.price_list,
          status: STATUSES.PENDING
        }
      };

      return newState;
    }

    case ACTIONS.PRICE_LIST_FETCH_FULLFILLED: {
      const newState = {
        ...state,
        price_lists: {
          data: state.price_lists.data.map(price_list => {
            return price_list.id === action.payload.id
              ? action.payload
              : price_list;
          }),
          status: STATUSES.FULFILLED
        }
      };

      return newState;
    }

    case ACTIONS.PRICE_LIST_FETCH_REJECTED: {
      const newState = {
        ...state,
        price_list: {
          ...state.price_list,
          status: STATUSES.REJECTED
        }
      };

      return newState;
    }

    case ACTIONS.PRICE_LISTS_FETCH_PENDING: {
      const newState = {
        ...state,
        price_lists: {
          ...state.price_lists,
          status: STATUSES.PENDING
        }
      };

      return newState;
    }

    case ACTIONS.PRICE_LISTS_FETCH_FULLFILLED: {
      const newState = {
        ...state,
        price_lists: {
          data: action.payload,
          status: STATUSES.FULFILLED
        }
      };

      return newState;
    }

    case ACTIONS.PRICE_LISTS_FETCH_REJECTED: {
      const newState = {
        ...state,
        price_lists: {
          ...state.price_lists,
          status: STATUSES.REJECTED
        }
      };

      return newState;
    }

    case ACTIONS.MAPPINGS_FETCH_PENDING: {
      const newState = {
        ...state,
        mappings: {
          ...state.mappings,
          status: STATUSES.PENDING
        }
      };

      return newState;
    }

    case ACTIONS.MAPPINGS_FETCH_FULLFILLED: {
      const newState = {
        ...state,
        mappings: {
          data: (action.payload || []),
          status: STATUSES.FULFILLED
        }
      };

      return newState;
    }

    case ACTIONS.MAPPINGS_FETCH_REJECTED: {
      const newState = {
        ...state,
        mappings: {
          ...state.mappings,
          status: STATUSES.REJECTED
        }
      };

      return newState;
    }

    case ACTIONS.TARIFFS_FETCH_PENDING: {
      const newState = {
        ...state,
        tariffs: {
          ...state.tariffs,
          status: STATUSES.PENDING
        }
      };

      return newState;
    }

    case ACTIONS.TARIFFS_FETCH_FULLFILLED: {
      const newState = {
        ...state,
        tariffs: {
          data: (action.payload || []),
          status: STATUSES.FULFILLED
        }
      };

      return newState;
    }

    case ACTIONS.TARIFFS_FETCH_REJECTED: {
      const newState = {
        ...state,
        mappings: {
          ...state.mappings,
          status: STATUSES.REJECTED
        }
      };

      return newState;
    }

    case ACTIONS.TARIFFS_BLOCK: {
      const newState = {
        ...state,
        tariffs: { ...state.tariffs, data: state.tariffs.data.map(tariff => ({ ...tariff, status: 'confirmed' as NSHotel.TTariffStatuses })) }
      };

      return newState;
    }

    case ACTIONS.TARIFFS_UNBLOCK: {
      const newState = {
        ...state,
        tariffs: { ...state.tariffs, data: state.tariffs.data.map(tariff => ({ ...tariff, status: 'created' as NSHotel.TTariffStatuses })) }
      };

      return newState;
    }

    case ACTIONS.COMPARE_RATE_ADD: {
      const newState = {
        ...state,
        compare: {
          ...state.compare,
          ...action.payload
        }
      };

      return newState;
    }

    case ACTIONS.COMPARE_RATE_REMOVE: {
      const newState = {
        ...state,
        compare: {}
      };

      return newState;
    }

    case ACTIONS.COMPARE_SERVICE_ADD: {
      const newState = {
        ...state,
        compare: {
          ...state.compare,
          ...action.payload
        }
      };

      return newState;
    }

    case ACTIONS.COMPARE_SERVICE_REMOVE: {
      const newState = {
        ...state,
        compare: {
          ...state.compare,
          price_list: null,
          service: null
        }
      };

      return newState;
    }

    case ACTIONS.COMPARE_SUBMIT: {
      const newState = {
        ...state,
        compare: {}
      };

      return newState;
    }

    case ACTIONS.COMPARE_RESET: {
      const newState = {
        ...state,
        compare: {}
      };

      return newState;
    }

    default:
      throw new Error();
  }
};

export default reducer;
