import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { computed, reaction } from 'mobx';
import { observer } from 'mobx-react';
import _ from 'lodash';

import RoomTypesStore from 'Stores/RoomTypesStore/List';
import SingleSelect from 'Shared/form/SingleSelectObj';

@observer
class RoomType extends Component {
  componentDidMount() {
    this.fetch();
    
    this.typeChangeHandler = reaction(
      () => this.props.form.$('type').value,
      async () => {
        await this.fetch();
      }
    );
  }

  componentWillUnmount() {
    const { roomTypesStore } = this.props;
    roomTypesStore.clear();
    this.typeChangeHandler();
  }

  updateRoomType(room_type) {
    const { roomTypesStore, form } = this.props;
    const roomTypes = roomTypesStore.room_types.toJSON();
    const currentRoomType = roomTypes.find(r => room_type.id === r.id);

    if (currentRoomType) {
      form.$('room_type').update(currentRoomType);
    } else {
      form.$('room_type').reset();
    }
  }

  async fetch() {
    const { roomTypesStore, form } = this.props;
    
    roomTypesStore.clear();
    
    await roomTypesStore.fetch();

    const room_type = form.$('room_type').value;
    
    if (room_type?.id) {
      this.updateRoomType(room_type);
    }
  }

  @computed get isLoading() {
    const { roomTypesStore } = this.props;
    return roomTypesStore.isPending;
  }

  @computed get roomTypesOptions() {
    const { roomTypesStore } = this.props;

    return roomTypesStore.isFetched
      ? roomTypesStore.room_types.toJSON()
      : [];
  }

  render() {
    const { form } = this.props;

    return (
      <SingleSelect
        field={form.$('room_type')}
        simpleValue={false}
        valueKey='id'
        labelKey='name'
        searchable
        clearable
        isLoading={this.isLoading}
        options={this.roomTypesOptions}
      />
    );
  }
}

RoomType.defaultProps = {
  roomTypesStore: RoomTypesStore.create()
};

RoomType.propTypes = {
  form: PropTypes.object.isRequired,
  roomTypesStore: PropTypes.object.isRequired
};

export default RoomType;
