import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';
import instanceBlob from 'connection/instanceBlob';

import { PriceList } from './PriceList';

const PriceListsStore = types.model(
  'PriceListsStore',
  {
    price_lists: types.optional(types.array(PriceList), []),
    selected_ids: types.optional(types.array(types.string), []),
    state: types.maybe(types.enumeration(['pending', 'done', 'error'])),

    get isFetched() {
      return this.state === 'done';
    },

    get isPending() {
      return this.state === 'pending';
    }
  },
  {
    setState(state, response = undefined) {
      this.state = state;
      return response || this;
    },

    toggle() {
      this.isActive = !this.isActive;
    },

    fetch(params = {}) {
      this.setState('pending');

      return instance
        .get('/api/price_lists', params)
        .then((response) => this.applySnapshot(response))
        .then((response) => this.setState('done', response))
        .catch((error) => this.errorHandler(error));
    },

    clear() {
      const data = { price_lists: [] };
      applySnapshot(this, data);
    },

    errorHandler(error) {
      this.setState('error');
      return Promise.reject(error);
    },

    applySnapshot(response) {
      const { data } = response;
      applySnapshot(this, data);

      return response;
    },

    toggleSelection(id) {
      const idx = this.selected_ids.indexOf(id);
      if (idx === -1) {
        this.selected_ids.push(id);
      } else {
        this.selected_ids.splice(idx, 1);
      }
    },

    clearSelection() {
      this.selected_ids.clear();
    },

    downloadSelected() {
      return instanceBlob
        .post('/api/price_lists/download', {
          data: {
            ids: this.selected_ids.slice()
          }
        })
        .then((response) => {
          const blob = new Blob([response.data]);
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'price_lists.xlsx');
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(url);
        });
    }
  }
);

export default PriceListsStore;
