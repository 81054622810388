import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledButton = styled.div`
  position: absolute;
  bottom: -40px;
  right: 20px;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

@inject('priceListsStore')
@observer
class DownloadButton extends Component {
  @computed get hasSelectedItems() {
    return this.props.priceListsStore.selected_ids.length > 0;
  }

  handleDownloadSelected = () => {
    this.props.priceListsStore.downloadSelected();
  };

  render() {
    if (!this.hasSelectedItems) return null;

    return (
      <StyledButton
        className="button green"
        onClick={this.handleDownloadSelected}
      >
        Скачать выбранные
      </StyledButton>
    );
  }
}

DownloadButton.propTypes = {
  priceListsStore: PropTypes.object
};

export default DownloadButton;
